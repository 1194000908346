@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");

@tailwind base;

@tailwind components;

@tailwind utilities;

.text-color{
    color: #38b2ac
}
